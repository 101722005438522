import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {useRef } from 'react';
import { Helmet } from 'react-helmet';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import entryAnimation from '../resources/entry-animation';
import Hero from '../sections/Hero/Hero';
import ImportantInfo from '../sections/ImportantInfo/ImportantInfo';
import SeeMore from '../components/SeeMore/SeeMore';
import AboutUs from '../sections/AboutUs/AboutUs';
import Creators from '../sections/Creators/Creators';
import Meetup from '../sections/Meetup/Meetup';
import Navbar from '../components/Navbar/Navbar';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import Footer from '../sections/Footer/Footer';
import CaseStudy from '../sections/CaseStudy/CaseStudy';
import Brands from '../sections/Brands/Brands';
import fadeInElementAnimation from '../resources/fade-in-element-animation';


const IndexPage = () => {
    const navbarBreakpointRef = useRef(null);
    const data = useStaticQuery(query);
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);
    gsap.registerPlugin(ScrollTrigger);
    gsap.config({ nullTargetWarn: false });
    const importantInfoRef = useRef(null);
    const creatorsRef = useRef(null);
    const seeMoreRef1 = useRef(null);
    const seeMoreRef2 = useRef(null);

    const caseStudyRef = useRef(null);
    fadeInElementAnimation([importantInfoRef, creatorsRef, seeMoreRef1,
        seeMoreRef2, caseStudyRef], '90');

    return (
        <main ref={mainRef}>
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>GetHero</title>
                <script>
                    history.scrollRestoration = 'manual'
                </script>
            </Helmet>
            <Hero texts={data.allMainPage.nodes[0].hero_section_texts} />
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <ImportantInfo
                ref={importantInfoRef}
                scrollTo={data.allMainPage.nodes[0].show_important_info}
                text={data.allMainPage.nodes[0].important_info_text}
                link={data.allMainPage.nodes[0].important_info_link}
            />
            <AboutUs
                header={data.allMainPage.nodes[0].about_us_header}
                firstParagraph={data.allMainPage.nodes[0].about_us_first_paragraph}
                secondParagraph={data.allMainPage.nodes[0].about_us_second_paragraph}
                scrollTo={!data.allMainPage.nodes[0].show_important_info}
            />
            <Creators ref={creatorsRef} creators={data.allCreator.nodes} />
            <SeeMore ref={seeMoreRef1} text="Zobacz więcej twórców" url="/nasi-tworcy" afterArrow id="see-more-1" />
            <CaseStudy caseStudies={data.allPosts.nodes} />
            <SeeMore ref={seeMoreRef2} text="Zobacz więcej case study" url="/wiedza" id="see-more-2" afterArrow />
            <Meetup
                header={data.allMainPage.nodes[0].meetup_header}
                paragraph={data.allMainPage.nodes[0].meetup_paragraph}
                ytVideoId={data.allMainPage.nodes[0].meetup_video_link}
            />
            <Brands
                photos={data.allMainPage.nodes[0].worked_with_logos}
            />
            <Footer/>
            <ScrollTop />
        </main>
    );
};

const query = graphql`
    query MainSettings {
      allCreator(filter: {main: {eq: true}}, sort: {order: ASC, fields: main_page_order}) {
        nodes {
          id
          name
          image {
            extension
            id
          }
          instagram_link
          tiktok_link
          youtube_link
          type
        }
      }
      allMainPage {
        nodes {
          about_us_first_paragraph
          about_us_header
          about_us_second_paragraph
          meetup_header
          meetup_paragraph
          meetup_video_link
          show_important_info
          important_info_link
          important_info_text
          hero_section_texts {
            text
          }
          worked_with_logos {
            id
            extension
            localFile {
              publicURL
            }
          }
        } 
      }
        allPosts(filter: {category: {eq: "Case study"}, show_on_main_page: {eq: true}}) {
        nodes {
          id
          slug
          category
          name
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      } 
    }`;

export default IndexPage;
