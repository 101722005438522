import React, {useEffect, useRef, useState} from 'react';
import './Meetup.scss';
import YouTube from 'react-youtube';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import fadeInElementAnimation from '../../resources/fade-in-element-animation';

const Meetup = ({header, paragraph, ytVideoId}) => {
    const [ytPlayer, setYtPlayer] = useState();
    const textRef = useRef(null);
    const ytRef = useRef(null);

    const sectionName = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        const tl = gsap.timeline();
        tl.to(ytRef.current, {
            scrollTrigger: {
                trigger: ytRef.current,
                start: 'top 90%',
                end: 'center 10%',
                onEnter: () => {
                    if (ytPlayer) {
                        ytPlayer.playVideo()
                    }
                },
                onLeave: () => {
                    if (ytPlayer) {
                        ytPlayer.pauseVideo()
                    }
                },
                onEnterBack: ()=> {
                    if (ytPlayer) {
                        ytPlayer.playVideo()
                    }
                },
                onLeaveBack: () => {
                    if (ytPlayer) {
                        ytPlayer.pauseVideo()
                    }
                }
            }
        })


    })
    fadeInElementAnimation([textRef, ytRef, sectionName]);


    return (
        <section className="meetup-section container-fluid">
            <div ref={sectionName} className="row no-gutters">
                <span className="section-name">MEETUP</span>
            </div>
            <div className="row no-gutters">
                <div ref={textRef} className="col-lg-6">
                    <h2 className="meetup-section__header">{header}</h2>
                    <div className="meetup-section__description">
                        <p>{paragraph}</p>
                    </div>
                    {/* <div className="d-none d-lg-block"> */}
                    {/*    <Button additionalClasses="btn--transparent">Więcej</Button> */}
                    {/* </div> */}
                </div>
                <div className="col-lg-6 d-flex align-items-center">
                    <div ref={ytRef} className="w-100">
                        <YouTube
                            videoId={ytVideoId}
                            containerClassName="video-wrapper"
                            onReady={(event) => {
                                setYtPlayer(event.target);
                            }}
                            opts={{
                                width: 560,
                                height: 315,
                                playerVars: {
                                    autoplay: 0,
                                    controls: 0,
                                    modestbranding: 1,
                                    showinfo: 0,
                                    mute: 1,
                                    rel: 0,
                                },
                            }}
                        />

                    </div>
                </div>
            </div>
            {/* <div className="row no-gutters d-lg-none"> */}
            {/*    <Button additionalClasses="btn--transparent">Więcej</Button> */}
            {/* </div> */}

        </section>
    );
};

export default Meetup
