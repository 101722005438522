import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import './Creators.scss';

const Creators = React.forwardRef(({ creators }, ref) => {
    useEffect(() => {
        new window.Slideshow(document.getElementById('grid-slideshow'));
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <script
                    src={withPrefix('libraries/CrossroadsSlideshow/js/TweenMax.min.js')}
                    type="text/javascript"
                />
                <script
                    src={withPrefix('libraries/CrossroadsSlideshow/js/CrossroadsSlideshow.js')}
                    type="text/javascript"
                />
            </Helmet>
            <section className="creators-section">
                <span className="creators-section__name">
                    <span
                        className="creators-section__section-name section-name section-name--dark-background "
                    >
                        NASI TWÓRCY
                    </span>
                </span>
                <div ref={ref} className="content">
                    <div className="grid grid--slideshow" id="grid-slideshow">
                        {
                            creators.map((creator) => {
                                const baseUrl = 'https://api.flotiq.com/image/500x690/';
                                const imageUrl = `${baseUrl}${creator.image[0].id}.${creator.image[0].extension}`;
                                const style = {
                                    backgroundImage: `url('${imageUrl}')`,
                                };
                                return (

                                    <figure className="grid__item grid__item--slide" key={creator.id}>
                                        <span className="number">
                                            {'#'}
                                            {creator.type}
                                        </span>
                                        <div className="img-wrap">
                                            <div className="img" style={style} />
                                        </div>
                                        <figcaption className="caption">
                                            {creator.youtube_link && (
                                                <a
                                                    href={`https://youtube.com/${creator.youtube_link}`}
                                                    className="caption__link"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="icon youtube-icon" />
                                                </a>
                                            )}
                                            {creator.instagram_link && (
                                                <a
                                                    href={`https://instagram.com/${creator.instagram_link}`}
                                                    className="caption__link"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="icon instagram-icon" />
                                                </a>
                                            )}
                                            {creator.tiktok_link && (
                                                <a
                                                    href={`https://tiktok.com/${creator.tiktok_link}`}
                                                    className="caption__link"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <em className="icon tiktok-icon" />
                                                </a>
                                            )}
                                        </figcaption>
                                    </figure>
                                );
                            })
                        }

                        <div className="titles-wrap">
                            <div className="grid grid--titles">
                                {
                                    creators.map((creator) => (
                                        <h3
                                            className="grid__item grid__item--title"
                                            key={creator.id}
                                        >
                                            {creator.name}
                                        </h3>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="grid grid--interaction">
                            <div className="grid__item grid__item--cursor grid__item--left" />
                            <div className="grid__item grid__item--cursor grid__item--space-left" />
                            <div className="grid__item grid__item--cursor grid__item--center" />
                            <div className="grid__item grid__item--cursor grid__item--space-right" />
                            <div className="grid__item grid__item--cursor grid__item--right" />
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
});

export default Creators;
