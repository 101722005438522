import React, { useEffect, useRef } from 'react';
import './Hero.scss';
import { Link } from 'react-scroll';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Image from '../../assets/hero/554A6835.jpg';
import Image2 from '../../assets/hero/554A6980.jpg';
import Image3 from '../../assets/hero/554A6938.jpg';
import Image4 from '../../assets/hero/554A7379.jpg';
import Image5 from '../../assets/hero/554A7540.jpg';
import Image6 from '../../assets/hero/554A7633.jpg';
import Image7 from '../../assets/hero/554A7729.jpg';
import Image8 from '../../assets/hero/554A7744.jpg';
import Image9 from '../../assets/hero/554A7826.jpg';
import Image10 from '../../assets/hero/554A7943.jpg';
import Image11 from '../../assets/hero/554A7957.jpg';
import Image12 from '../../assets/hero/554A7976.jpg';
import Image13 from '../../assets/hero/554A8207.jpg';
import Image14 from '../../assets/hero/554A8332.jpg';
import Image15 from '../../assets/hero/554A8412.jpg';
import Image16 from '../../assets/hero/554A8516.jpg';
import Image17 from '../../assets/hero/554A8537.jpg';
import Image18 from '../../assets/hero/554A9048.jpg';
import ArrowDown from '../../assets/arrow-down-wt.svg';

function Hero({ texts, additionalClasses = [] }) {
    gsap.registerPlugin(ScrollTrigger);
    const heroTextsRef = useRef(null);
    const heroSliderRef = useRef(null);
    useEffect(() => {
        const heroSection = document.getElementById('hero-section');
        const heroText = heroSliderRef.current;
        const heroArrow = document.getElementById('hero-arrow');
        const imagesMovingLines = Array.from(
            document.getElementsByClassName(' tiles__moving'),
        ).map((line) => line.getElementsByTagName('div'));
        let imagesMoving = [];
        imagesMovingLines.forEach((line) => {
            imagesMoving = [...imagesMoving, ...Array.from(line)];
        });
        const windowHeight = window.innerHeight;
        const animation = gsap.fromTo(
            imagesMoving,
            { marginTop: 0, marginBottom: '3vw' },
            { marginTop: '-=300px', marginBottom: '+=300px' },
        );
        ScrollTrigger.create({
            start: 'top top',
            end: 'bottom bottom',
            trigger: heroSection,
            scrub: true,
            onLeave: () => {
                heroText.style.top = windowHeight > 1080
                    ? `${heroSection.scrollHeight - windowHeight * 0.6}px` : '100vh';
                heroText.style.position = 'absolute';
                heroArrow.style.top = windowHeight > 1080
                    ? `${heroSection.scrollHeight - windowHeight * 0.1}px` : '150vh';
                heroArrow.style.position = 'absolute';
            },
            onEnter: () => {
                heroText.style.top = '40vh'; heroText.style.position = 'fixed';
                heroArrow.style.top = '90vh'; heroArrow.style.position = 'fixed';
            },
            onEnterBack: () => {
                heroText.style.top = '40vh'; heroText.style.position = 'fixed';
                heroArrow.style.top = '90vh'; heroArrow.style.position = 'fixed';
            },
        });
        ScrollTrigger.create({
            start: 'top top',
            end: 'bottom top',
            trigger: heroSection,
            scrub: true,
            animation,
        });
    }, []);

    useEffect(
        () => {
            const tl = gsap.timeline({ repeat: -1 });
            if (heroTextsRef.current.children) {
                [...heroTextsRef.current.children].forEach((child) => {
                    tl.fromTo(child, { opacity: 0 },
                        { opacity: 1,
                            duration: 0.5 }).to(child, { opacity: 0, delay: 1, duration: 0.5 });
                });
            }
        },
        [],
    );
    return (
        <section className={['hero-section', ...additionalClasses].join(' ')} id="hero-section">

            <section className="tiles tiles--columns-rotated" id="grid">
                <div className="tiles__wrap">
                    <div className="tiles__line">
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image14})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image15})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image16})` }} />
                    </div>
                    <div className="tiles__line tiles__moving">
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image3})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image4})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image10})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image8})` }} />
                    </div>
                    <div className="tiles__line">
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image2})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image5})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image9})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image6})` }} />
                    </div>
                    <div className="tiles__line tiles__moving">
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image11})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image12})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image7})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image13})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image18})` }} />
                    </div>
                    <div className="tiles__line">
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image17})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                        <div className="tiles__line-img" style={{ backgroundImage: `url(${Image})` }} />
                    </div>
                </div>
            </section>
            <div ref={heroSliderRef} className="hero-wrapper">
                <div className="hero-text" ref={heroTextsRef}>
                    {texts[0] && texts.map((item) => (
                        <h1 key={item.text}>{item.text}</h1>
                    ))}
                </div>
            </div>
            <Link
                className="hero-section__arrow-down"
                to="arrow-down-position"
                spy
                smooth
                duration={500}
                id="hero-arrow"
            >
                <img src={ArrowDown} alt="" />
            </Link>
        </section>
    );
}

export default Hero;
