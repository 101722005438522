import React, {useEffect, useRef} from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Button from '../../components/Button/Button';
import HashtagFilled from '../../assets/hashtag-filled.svg';
import HashtagEmpty from '../../assets/hashtag-empty.svg';
import './AboutUs.scss';

function AboutUs({header, firstParagraph, secondParagraph, scrollTo}) {
    const cover = useRef(null);
    const emptyHashtagSection = useRef(null);
    const filledHashtagSection = useRef(null);
    const sectionContent = useRef(null);
    const startingPercent = '50'

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.fromTo(cover.current, {},  {
            scrollTrigger: {
                trigger: emptyHashtagSection.current,
                start: 'top top',
                end: `${startingPercent}% -30%`,
                pin: filledHashtagSection.current
            }
        });
        gsap.fromTo(cover.current, {},  {
            scrollTrigger: {
                trigger: emptyHashtagSection.current,
                start: 'top top',
                end: `${startingPercent}% -30%`,
                pin: emptyHashtagSection.current

            }
        });
    }, []);


    return (
        <div className="about-us-section-wrapper" id={scrollTo ? 'arrow-down-position' : ''}>
            <section ref={emptyHashtagSection} className="about-us-section--empty">
                <img className="about-us-section__image" src={HashtagEmpty}/>
            </section>
            <section ref={filledHashtagSection} className="about-us-section--outlined">
                <img className="about-us-section__image" src={HashtagFilled}/>
            </section>

            <section ref={cover} className="about-us-section--cover"/>
            <section ref={sectionContent} className="about-us-section--filled">
                <span className="section-name">O NAS</span>
                <h2 className="about-us-section__header">{header}</h2>
                <div className="about-us-section__description">
                    <p className="description-paragraph">{firstParagraph}</p>
                    <p className="description-paragraph">{secondParagraph}</p>
                    <a href="/o-nas/pl">
                        <Button additionalClasses="btn--transparent">Więcej</Button>
                    </a>
                </div>
            </section>
            <div/>
        </div>
    );
}

export default AboutUs;
