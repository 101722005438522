import React, { useEffect } from 'react';
import './ImportantInfo.scss';
import { Link } from 'gatsby';
import gsap from 'gsap';

const ImportantInfo = React.forwardRef(({ scrollTo, text, link }, ref) => {
    useEffect(() => {
        const time = 50;
        const rollingTextgoLeft = document.getElementsByClassName('important-info-section__slider');

        function startRolling() {
            Array.from(rollingTextgoLeft).forEach((rolling) => {
                const __text = rolling.getElementsByClassName('important-info-section__text')[0];
                const width = __text.clientWidth;
                __text.style.marginLeft = `${-width}px`;
                gsap.fromTo(rolling, { x: 0 }, {
                    x: `+=${width}`,
                    ease: 'none',
                    repeat: -1,
                    duration: width / time,
                });
            });
        }
        if (document.readyState === 'complete') {
            startRolling();
        } else {
            window.addEventListener('load', startRolling);
        }
    }, []);

    return scrollTo ? (
        <section className="important-info-section" id={scrollTo ? 'arrow-down-position' : ''}>
            <Link className="important-info-section__link" to={link}>
                <div ref={ref} className="important-info-section__slider">
                    { [...Array(10).keys()].map((item) => (
                        <div
                            className="important-info-section__text"
                            key={item}
                        >
                            {text}
                        </div>
                    )) }
                </div>
            </Link>
        </section>
    ) : '';
});

export default ImportantInfo;
